import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import './animate.css';
import Home from './pages/index';

function App() {
  return (
    <Router>
      <Fragment>
        <Routes>
          <Route path="/"  element={<Home />} />
        </Routes>
      </Fragment>
    </Router>
  );
}

export default function Root() {
  return (
    <App />
  )
}

ReactDOM.render(<Root />, document.getElementById('root'));
