import React, { useState, useEffect, Fragment } from "react"
import axios from 'axios'
import Card from "../components/Card"
import User from "../components/User"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Title from "../components/Title"
import Breadcrumb from "../components/Breadcrumb"
import usePrevious from '../hooks/usePrevious'
import NotFoundData from "../components/NotFoundData"

const arraySkeleton = [1, 2, 3, 4, 5, 6, 7, 8];

const SkeletonBlock = () => {
  return (
    <>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 px-0 md:px-4 lg:px-6">
        {arraySkeleton?.map((_, index) => (
          <div key={index} className="bg-white rounded w-full md:w-72 lg:w-96 mx-auto my-4">
            <div className={`flex items-center justify-center bg-gray-200 h-36 p-3 overflow-hidden animate-pulse rounded-xl`}>
              <div className="h-4 w-16 bg-gray-300 rounded animate-pulse"></div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

const url = "https://bot.shopinzen.com"

export default function Search() {

  // State sidebar
  const [dataStep1, setDataStep1] = useState([]),
    [dataStep2, setDataStep2] = useState([]),
    [dataStep3, setDataStep3] = useState([]),
    [dataStep, setDataStep] = useState({
      year: '',
      month: '',
      theme: '',
    }),
    [users, setUsers] = useState([]),
    [loading, setLoading] = useState(false),
    [step, setStep] = useState(1),
    [currentLink, setCurrentLink] = useState('');

  const prevLink = usePrevious(currentLink);

  const getDataByStep = async (endPoint) => {
    setLoading(true);
    await axios.get(`${url}${endPoint}`).then((res) => {
      const { data } = res.data
      console.log('data', data)

      if (step === 1) {
        setDataStep1(data);
      } else if (step === 2) {
        setDataStep2(data)
      } else if (step === 3) {
        setDataStep3(data)
      } else if (step === 4) {
        setUsers(data);
      }

      setTimeout(() => {
        setLoading(false)
      }, 1000)

    }).catch((error) => console.log('error', error))
  }

  useEffect(() => {
    getDataByStep('/rankings')
  }, []);

  const handleShow = (data) => {
    // console.log('data', data)
    setCurrentLink(data?.link)
    if (step === 1) {
      setStep(2);
      setDataStep({...dataStep, year:data?.name})
    } else if (step === 2) {
      setStep(3);
      setDataStep({...dataStep, month:data?.name})
    } else if (step === 3) {
      setStep(4);
      setDataStep({...dataStep, theme:data?.name})
    }
  }

  useEffect(() => {
    getDataByStep(currentLink);
  }, [step])

  const begin = () => {
    setStep(1);
    setDataStep({...dataStep, theme:'', year:'', month:''})
    setCurrentLink("/rankings")
    getDataByStep('/rankings')
  }

  // const showMonth = () => {
  //   setStep(2);
  //   setDataStep({...dataStep, month:'', theme:''})
  //   console.log("prevLink", prevLink);
  //   getDataByStep(prevLink);
  // }

  const CardList = ({ data, text }) => {
    return (
      <div className="flex flex-col justify-center space-y-4 w-full sh-animate-bottom">
        <Title text={text} />
        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
          {data?.map((card, index) => (
            <Card
              card={card}
              key={index}
              index={index}
              onClick={() => handleShow(card)}
            />
          ))}
        </div>
      </div>
    )
  }

  const UserList = ({ users, text }) => {
    return (
      <div className="flex flex-col justify-center space-y-4 w-full sh-animate-left">
        <Title text={text} />
        <div className="grid grid-rows-40 md:grid-rows-12 lg:grid-rows-9 xl:grid-rows-8 grid-flow-col gap-4">
          {users?.map((user, index) => (
            <User
              user={user}
              key={index}
              index={index}
            />
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col justify-between h-screen">
      <Header />

      <div className="p-8 space-y-8">
        <div className="relative">

          <div className="flex justify-center items-center 3xl:mb-8 px-6 mb-8">
            <h3 className="text-lg md:text-xl lg:text-2xl 2xl:text-3xl xl:leading-10 font-bold text-gray-500">INFLUENCER RANK</h3>
          </div>

          <div className="h-auto px-6 p-4 w-full flex flex-row justify-between border border-gray-200">
            <Breadcrumb 
              step={step} 
              dataStep={dataStep}
              showYear={()=>begin()}
              showMonth={()=>begin()}
              />
            <div className="flex items-end">
              <button className="px-4 py-2 bg-pblue text-white" onClick={()=>begin()}>Begin</button>
            </div>
          </div>
        </div>

        <div className="h-auto mt-36 px-2 md:px-8 w-full flex flex-col justify-center items-center overflow-y-auto">

          {step === 1 &&
            <Fragment>
              {loading ? <SkeletonBlock /> :
                <CardList data={dataStep1} text={"Choose the year before CONTINUING..."} />
              }
            </Fragment>
          }
          {step === 2 &&
            <Fragment>
              {loading ? <SkeletonBlock /> :
                <CardList data={dataStep2} text={"Choose the month before CONTINUING..."} />
              }
            </Fragment>
          }
          {step === 3 &&
            <Fragment>
              {loading ? <SkeletonBlock /> :
                <CardList data={dataStep3} text={"Choose the theme before CONTINUING..."} />
              }
            </Fragment>
          }
          {step === 4 &&
            <Fragment>
              {loading ? <SkeletonBlock /> :
                <UserList users={users} text={`${'TOP '}${users.length} ${dataStep.theme} - ${dataStep.month} ${dataStep.year}`} />
              }
              {!loading&&users.length===0&& <NotFoundData />}
            </Fragment>
          }
        </div>
      </div>
      <Footer />
    </div>
  )
}
