
const Footer = () => {
    return (
        <footer className="bg-gray-200 text-center lg:text-left">
            <div className="text-gray-700 text-center p-4"> © 2022 Copyright
                <a className="text-gray-800" target="_blank" href="https://seedfounders.tech/">Influencer Rank - SeedFounders </a>
            </div>
        </footer>
    )
}
export default Footer;
