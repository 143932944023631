import React, { useState } from 'react'

const Header = () => {

    const [showNav, setShowNav] = useState(false);

    return (
        <nav id="navbartop" className="relative bg-white shadow w-full">
            <div className="px-6 py-4 mx-auto md:flex md:justify-between md:items-center">
                <div className="flex items-center justify-between">
                    <a target='_blank' href="https://seedfounders.tech">
                        <img
                            className="w-48"
                            src="images/mandalore.png"
                            alt="Logo piano piano"
                        />
                    </a>

                    <div className="flex lg:hidden">
                        <button onClick={() => setShowNav(!showNav)} type="button" className="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400" aria-label="toggle menu">
                            {!showNav &&
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="block md:hidden w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                </svg>
                            }

                            {showNav && <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                            }
                        </button>
                    </div>
                </div>

                <div className={`${showNav ? 'block translate-x-0 opacity-100 ' : ' hidden opacity-0 -translate-x-full '}${' absolute inset-x-0 w-full z-10 px-6 py-4 transition-all duration-300 ease-in-out bg-white md:mt-0 md:p-0 md:top-0 md:relative md:bg-transparent md:w-auto md:opacity-100 md:translate-x-0 md:flex md:items-center'}`}>
                    <div id="navbar" className="flex flex-col md:flex-row items-center md:mx-6 space-x-6 h-full z-50">
                    </div>
                    <div className="flex justify-center md:block z-50 ">
                    </div>
                </div>
            </div>
        </nav>
    )
}
export default React.memo(Header);
