const Card = ({ index, card, onClick }) => {
    return (
        <div
            key={index}
            className={`flex items-center justify-center w-full h-36 bg-yellowgray mt-8 cursor-pointer text-lg md:text-3xl text-white rounded-xl`}
            onClick={onClick}
        >
            {card.name}
        </div>
    )
}
export default Card;