import React from "react";

const NotFoundData = () => {

   return (
	<div className="flex justify-center my-24">
        <img src="images/assets/notfound2.png" alt="not found" className="w-48 md:w-96 h-auto object-cover transform scale-150"/>
    </div>
    )
}
export default React.memo(NotFoundData);

