const User = ({ user }) => {
    return (
        <div className="flex flex-row items-center space-x-6 hover:shadow-lg p-2 rounded-lg border border-gray-200 cursor-pointer">
            <div className="flex-shrink-0 w-auto rounded-full">
                <img 
                    className="w-auto rounded-full shadow-2xl" 
                    alt={user?.username}
                    src={`${user?.users_infos?.profile_image_url}`}
                />
            </div>
            <div>
                <h3 className="text-md md:text-lg lg:text-xl 2xl:text-2xl xl:leading-10 font-bold text-gray-500 capitalize">{user?.position} . {user?.username} <span className="text-indigo-400 lowercase">@{user?.username}</span></h3>
                <p className="text-lgp font-light text-gray-500">{user?.users_infos?.description}</p>
            </div>
        </div>
    )
}
export default User;
